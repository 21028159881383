var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-group',{attrs:{"no-action":"","color":"white","value":_vm.$route.meta.parent === 'tickets.index',"active-class":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Tickets")])]},proxy:true}],null,true)},[_c('div',{staticClass:"tw-flex tw-items-center",attrs:{"slot":"appendIcon"},slot:"appendIcon"},[(hover)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"tw-mr-1",attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.init.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fal fa-sync")])],1)]}}],null,true)},[_c('span',[_vm._v("Sync")])]):_vm._e(),_c('v-icon',[_vm._v("fal fa-angle-down")])],1),_c('v-icon',{attrs:{"slot":"prependIcon","dense":""},slot:"prependIcon"},[_vm._v("fal fa-clipboard-list fa-fw")]),(_vm.loading)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-btn',{attrs:{"loading":"","text":""}})],1)],1)],1):[_c('v-list-item',{attrs:{"active-class":"grey darken-4","to":{
                        name: 'tickets.index',
                    },"exact":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" All ")])],1)],1),_vm._l((_vm.departments),function(department){return [_c('v-list-item',{key:department.id,attrs:{"active-class":"grey darken-4","to":{
                            name: 'tickets.index',
                            query: {
                                department: department.id,
                                department_title: department.title,
                            }
                        },"exact":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(department.title)+" ")])],1)],1)]})]],2)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }