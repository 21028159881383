<template>
    <div>
        <v-hover v-slot="{ hover }">
            <v-list-group
                no-action
                color="white"
                :value="$route.meta.parent === 'tickets.index'"
                active-class="black"
            >
                <template v-slot:activator>
                    <v-list-item-title>Tickets</v-list-item-title>
                </template>
                <div
                    slot="appendIcon"
                    class="tw-flex tw-items-center"
                >
                    <v-tooltip top v-if="hover">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                small
                                class="tw-mr-1"
                                @click.stop="init"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon x-small>fal fa-sync</v-icon>
                            </v-btn>
                        </template>
                        <span>Sync</span>
                    </v-tooltip>                    
                    <v-icon>fal fa-angle-down</v-icon>
                </div>
                <v-icon slot="prependIcon" dense>fal fa-clipboard-list fa-fw</v-icon>
                <v-list-item v-if="loading">
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-btn loading text></v-btn>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <template v-else>
                    <v-list-item
                        active-class="grey darken-4"
                        :to="{
                            name: 'tickets.index',
                        }"
                        exact
                    >
                        <v-list-item-content>
                            <v-list-item-title>
                                All
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <template v-for="department in departments">
                        <v-list-item
                            active-class="grey darken-4"
                            :to="{
                                name: 'tickets.index',
                                query: {
                                    department: department.id,
                                    department_title: department.title,
                                }
                            }"
                            exact
                            :key="department.id"
                        >
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ department.title }}
                                    </v-list-item-title>
                                </v-list-item-content>                                
                            </v-list-item>
                    </template>
                </template>
            </v-list-group>
        </v-hover>
    </div>
</template>

<script>
import Http from '@/utils/httpClient';

export default {
    name: 'ticket.menu.item',
    data() {
        return {
            loading: false,
            departments: [],
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.loading = true;
            Http.get('ticket-departments', {
                params: {
                    per_page: -1,
                    sorters: [
                        {
                            column: 'title',
                            direction: 'asc',
                        }
                    ],
                    query: {
                        ticket_department: [
                            'id',
                            'title',
                        ]
                    }
                }
            })
                .then(({ data }) => {
                    this.loading = false;
                    this.departments = data.data;
                })
                .catch(() => {
                    this.loading = false;
                })
        },
    }
}
</script>

<style scoped>

</style>
